const config = {
    ossMaxSize: 1024 * 1024 * 50, // 50M

    baseMenus: [
        { name: '业务运营数据', path: 'businessConsole' },
        { name: '设备运营数据', path: 'deviceConsole' },
        {
            name: '报单',
            path: 'declaration',
            children: [
                { name: '我的报单', path: 'my' },
                { name: '报单申请', path: 'apply' },
                { name: '报单信息', path: 'detail' },
                { name: '操作记录', path: 'operate' },
                { name: '选择代理商', path: 'agent' }
            ]
        }
    ],

    createMenu(menus, path = '', routers = []) {
        menus.map((item) => {
            const _path = `${path}/${item.path}`
            if (item.children) {
                config.createMenu(item.children, _path, routers)
            } else {
                routers.push({
                    path: _path,
                    meta: { name: item.name },
                    component: () => import(`@/views/pages${_path}.vue`)
                })
            }
        })
        return routers
    },

    // 正则，用于校验
    pattern: {
        // 邮件
        email: /^\+?[a-z0-9](([-+.]|[_]+)?[a-z0-9]+)*@([a-z0-9]+(\.|\-))+[a-z]{2,6}$/,
        // 密码; 1 可以全数字，2 可以全字母，3 可以全特殊字符(~!@#$%^&*.)，4 三种的组合，5 可以是任意两种的组合，6 长度6-22
        pass: /^[\@A-Za-z0-9\!\#\$\%\^\&\*\.\~]{5,22}$/,
        // 验证码
        sms: /^\d{4}$/,
        // 公司名称
        companyName: /^[\(\)\（\）\u4E00-\u9FA5]+$/,
        // 信用代码
        uscc: /^[1-9A-GY]{1}[1239]{1}[1-5]{1}[0-9]{5}[0-9A-Z]{10}$/,
        // 身份证
        card: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
        // 手机号（支持手机号码，3-4位区号，7-8位直播号码，1－4位分机号）
        phone: /^(\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$/,
        // 全数字
        number: /^[0-9]*$/,
        // 数字或小数
        number2: /^[0-9]+\.{0,1}[0-9]{0,2}$/,
        // 用户名
        regName: /^[a-zA-z][a-zA-Z0-9_]{4,16}$/,
        // 手机号
        mobile: /^1[3456789]\d{9}$/,
        // ip 地址
        ip: /^(((\d{1,2})|(1\d{1,2})|(2[0-4]\d)|(25[0-5]))\.){3}((\d{1,2})|(1\d{1,2})|(2[0-4]\d)|(25[0-5]))$/,
        //全汉字
        chinese: /[\u4e00-\u9fd5]/g
    }
}

export default config

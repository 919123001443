const util = {
    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time))
    },
    // 随机码
    guid() {
        return 'xxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8
            return v.toString(16)
        })
    },
    // 深拷贝
    deepcopy(obj, cache = []) {
        function find(list, f) {
            return list.filter(f)[0]
        }

        // just return if obj is immutable value
        if (obj === null || typeof obj !== 'object') {
            return obj
        }

        // if obj is hit, it is in circular structure
        const hit = find(cache, (c) => c.original === obj)
        if (hit) {
            return hit.copy
        }

        const copy = Array.isArray(obj) ? [] : {}
        // put the copy into cache at first
        // because we want to refer it in recursive deepCopy
        cache.push({
            original: obj,
            copy
        })

        Object.keys(obj).forEach((key) => {
            copy[key] = util.deepcopy(obj[key], cache)
        })

        return copy
    },

    // 函数的去抖动
    debounce(method, delay = 500) {
        let timer = null
        return function () {
            const _this = this,
                args = arguments
            clearTimeout(timer)
            timer = setTimeout(function () {
                method.apply(_this, args)
            }, delay)
        }
    },
    // 函数的节流
    throttle(method, interval = 500) {
        let timer = null
        let lastTime = null
        return function () {
            const _this = this,
                args = arguments
            const nowTime = new Date()
            if (lastTime && nowTime - lastTime < interval) {
                clearTimeout(timer)
                timer = setTimeout(function () {
                    lastTime = nowTime
                    method.apply(_this, args)
                }, interval)
            } else {
                lastTime = nowTime
                method.apply(_this, args)
            }
        }
    },

    // 扩展图片类型和icon   icon地址对应:/static/public/{icon}@2x.png
    fileExtra(path, type) {
        type = type || path.substr(path.lastIndexOf('.') + 1).toLowerCase()
        let icon
        const icons = {
            word: ['doc', 'docx'],
            excel: ['xls', 'xlsx'],
            ppt: ['ppt', 'pptx'],
            pdf: ['pdf'],
            image: ['gif', 'jpg', 'jpeg', 'png'],
            zip: ['zip']
        }
        for (let key in icons) {
            if (icons[key].includes(type)) {
                icon = key
                break
            }
        }
        return {
            type,
            icon
        }
    },
    // 判断当前环境是微信小程序公众号浏览器
    getOs() {
        const agent = navigator.userAgent.toLocaleLowerCase()
        const ios = /iPad|iPhone|iPod/.test(agent) && !window.MSStream
        const mac = /macintosh|mac os x/i.test(agent) && !window.MSStream
        const android = /Android/.test(agent) && !window.MSStream
        const windows = /win32|wow32|win64|wow64/.test(agent)
        const miniProgram = /miniprogram/.test(agent)
        const wx = /micromessenger/.test(agent)
        return {
            os: windows ? 'windows' : mac ? 'macos' : android ? 'android' : ios ? 'ios' : 'other',
            platform: miniProgram ? 'mini' : wx ? 'wx' : 'other'
        }
    }
}
util.env = util.getOs()
export default util

import { createRouter, createWebHashHistory } from 'vue-router'
import { showNotify } from 'vant'
import store from '@/modules'
import http from '@/common/http'

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes: [
        { path: '/login', meta: { name: '登录' }, component: () => import('@/views/frame/login.vue') },
        { path: '/', redirect: '/businessConsole' }
    ]
})

router.beforeResolve((to) => {
    const user = store.state.user
    let c1 = to.query.companyid
    let c2 = user.companyid
    if (Array.isArray(c1)) c1 = c1[0]
    if (c1 && c2 && c1 != c2) store.commit('user/set_loginout')
    const companyid = c1 || c2
    if (user.login) {
        if (to.matched.length === 0) {
            showNotify({ message: '未找到路由!', type: 'danger' })
            return false
        }
        document.title = to.meta.name
        return true
    } else {
        const token = to.query.token || user.token
        if (token) {
            return http.get('Login/authInfo', { params: { companyid }, headers: { Authentication: token }, custom: { toast: false, auth: false } }).then(
                (payload) => {
                    store.commit('user/set_login', { token, companyid, user: payload })
                    delete to.query.token
                    delete to.query.companyid
                    router.addRoute(user.route)
                    router.replace({ path: to.path, query: to.query })
                },
                (err) => {
                    if (err?.code === 101) {
                        store.dispatch('user/login', companyid)
                    } else if (err?.code === 104) {
                        router.push('/login')
                    }
                }
            )
        } else if (to.path === '/login') {
            document.title = to.meta.name
            return true
        } else if (to.query?.code == '104') {
            router.push('/login')
        } else {
            store.dispatch('user/login', companyid)
        }
    }
})

export default router

import axios from 'axios'
import store from '@/modules'
import { showNotify, closeToast } from 'vant'
import router from '@/router'

const http = axios.create({ baseURL: '/shopbusiness' })

const err = (msg) => Promise.reject(msg)

http.interceptors.request.use(async (config) => {
    config.headers.submittype = 'shopwap'
    if (config.custom?.auth !== false) {
        const { token } = store.state.user
        if (!token) return err('无token')
        config.headers.Authentication = token
    }
    return config
})

http.interceptors.response.use((response) => {
    const {
        data,
        status,
        config: { custom }
    } = response
    if (status === 200) {
        if (data.code !== 0) {
            if (!custom || custom.toast !== false) {
                showNotify({ message: data.message, type: 'danger' })
                closeToast()
            }
            if (data.code === 101) {
                store.commit('user/set_loginout')
                store.dispatch('user/login')
            } else if (data.code === 104) {
                router.push('/login')
            }
            return Promise.reject(data)
        }
        return data.result || data.data || {}
    } else {
        return Promise.reject(response)
    }
})

export default http

import store from './index'
import api from '@/common/api'
import { stringifyQuery } from 'vue-router'
import config from '../common/config'
import util from '../common/util'
import router from '../router'
import http from '@/common/http'

const local = JSON.parse(localStorage.getItem('local')) || {}

store.registerModule('user', {
    namespaced: true,
    state: {
        login: false,
        companyid: local.companyid,
        token: local.token,
        menus: [],
        user: local.user || {},
    },
    mutations: {
        set_login(state, { token, companyid, user }) {
            state.login = true
            state.token = token
            state.companyid = companyid
            state.user = user ? user : {}
            state.route = {
                path: '/',
                component: () => import('../views/frame/FrameLayout.vue'),
                children: config.createMenu(config.baseMenus)
            }
            localStorage.setItem('local', JSON.stringify({ token, companyid, user }))
        },

        set_loginout(state) {
            state.login = false
            state.token = null
            state.companyid = null
            state.user = null
            localStorage.removeItem('local')
        }
    },
    actions: {
        login(_, companyid) {
            if (util.env.platform === 'wx') {
                const query = {
                    redirect_url: encodeURIComponent(location.href),
                    companyid
                }
                location.href = `${api.apiUrl}Login/index?` + stringifyQuery(query)
            } else {
                router.push('/login')
            }
        },

        async getAuthInfo(store, { token, companyid }) {
            const authInfo = await http.get('Login/authInfo', { params: { companyid }, headers: { Authentication: token }, custom: { auth: false } })
            store.commit('set_login', { token, companyid, user: authInfo })
        }
    }
})
